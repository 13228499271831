import React, { Component } from "react";
import { Column, Row } from "simple-flexbox";
import Select from "@sm/components/custom/Select";

class Multiselect extends Component {
  state = {
    selectedItems: [],
    temporarySelectedItem: {},
    showError: false,
    errorMessage: "",
    newRowClicked: false,
  };

  componentDidMount() {
    const { selectedItems } = this.props;
    if (selectedItems) {
      this.setState({
        selectedItems: selectedItems,
      });
    }
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;
    if (oldProps.selectedItems.length !== newProps.selectedItems.length) {
      this.setState({
        selectedItems: newProps.selectedItems,
        temporarySelectedItem: {},
      });
    }

    if (oldProps.update !== newProps.update) {
      this.setState({
        newRowClicked: false,
      });
    }
  }

  getFilteredOptions = () => {
    const { items } = this.props;
    const { selectedItems, temporarySelectedItem } = this.state;
    return items.filter(
      (item) =>
        !selectedItems.some(
          (selectedItem) => selectedItem.value === item.value
        ) || item.value === temporarySelectedItem.value
    );
  };

  onSelectItemChange = (value) => {
    this.setState(
      {
        temporarySelectedItem: value,
      },
      this.onTemporaryItemChange
    );
  };

  onTemporaryItemChange = () => {
    const { temporarySelectedItem } = this.state;
    this.props.onTemporaryChange(
      this.props.selectedId,
      temporarySelectedItem.value
    );
  };

  onInputItemChange = (event) => {
    const value = event.target.value.trim();
    this.setState(
      {
        temporarySelectedItem: {
          label: value,
          value: value,
        },
        showError: false,
        errorMessage: ""
      },
      this.onTemporaryItemChange
    );
  };

  inputCheckError = (isSaveClicked) => {
    const { temporarySelectedItem } = this.state;

    this.props.onTemporaryChange(
      this.props.selectedId,
      temporarySelectedItem.value
    );

    this.setState({
      showError: false,
      errorMessage: "",
    });

    return false;
  };

  onAddItemChange = () => {
    const { selectedItems, temporarySelectedItem, newRowClicked } = this.state;

    if (!newRowClicked && selectedItems.length > 0) {
      this.setState({
        newRowClicked: true,
      });

      return;
    }

    const isError = this.inputCheckError();

    if (
      !temporarySelectedItem.value ||
      selectedItems.some((elem) => elem.value === temporarySelectedItem.value)
    ) {
      return;
    }

    if (!isError) {
      selectedItems.push(temporarySelectedItem);
      this.props.onChange(
        this.props.selectedId,
        selectedItems.map((selectedItem) => selectedItem.value)
      );

      this.setState({
        selectedItems: selectedItems,
        temporarySelectedItem: {},
        showError: false,
        errorMessage: "",
        newRowClicked: true,
      });
    }
  };

  onDeleteItemChange = (value) => {
    const { selectedItems } = this.state;

    if (!value) {
      this.setState({
        newRowClicked: false,
        temporarySelectedItem: {},
        showError: false,
        errorMessage: "",
      });

      return;
    }

    const deleteIndex = selectedItems.findIndex((elem) => elem.value === value);
    selectedItems.splice(deleteIndex, 1);
    this.props.onChange(
      this.props.selectedId,
      selectedItems.map((selectedItem) => selectedItem.value)
    );
    this.setState({
      selectedItems: selectedItems,
      temporarySelectedItem: {},
    });
  };

  render() {
    const props = this.props;
    const {
      selectedItems,
      temporarySelectedItem,
      newRowClicked,
      showError,
      errorMessage
    } = this.state;

    return (
      <Column
        className="multiselect"
        flexGrow={ 1 }
        vertical="start"
        alignSelf="start"
      >
        <Row className="multiselect-header">{props.header}</Row>
        <Column
          className="multiselect-body"
          flexGrow={ 1 }
          vertical="start"
          alignSelf="start"
        >
          {selectedItems.map((elem, i) => {
            return (
              <Row
                key={ elem.value }
                flexGrow={ 1 }
                horizontal="start"
                className="selected-items"
              >
                <input
                  readOnly
                  className="form-control multiselect-select"
                  value={ elem.label }
                />
                {props.deletable && (
                  <Row
                    className="actions"
                    flexGrow={ 1 }
                    horizontal="start"
                    wrap={ true }
                    vertical="start"
                    style={ { visibility:  props.disabled ? "hidden" : "visible" } }
                  >
                    <button
                      className="delete"
                      onClick={ () => this.onDeleteItemChange(elem.value) }
                    >
                      X
                    </button>
                    {!newRowClicked && i === selectedItems.length - 1 && (
                      <button
                        className="add"
                        onClick={ () => this.onAddItemChange() }
                      >
                        <span className="plus"> + </span>
                      </button>
                    )}
                  </Row>
                )}
              </Row>
            );
          })}
          {(selectedItems.length === 0 || newRowClicked) && props.deletable && (
            <Row
              style={ { width: "100%", marginTop: "10px" } }
              flexGrow={ 1 }
              horizontal="start"
              className="selected-items"
            >
              {props.type === "select" ? (
                <Select
                  className="multiselect-select"
                  value={ temporarySelectedItem.value || "Select" }
                  clearable={ false }
                  onChange={ (value) => this.onSelectItemChange(value) }
                  options={ this.getFilteredOptions() }
                />
              ) : (
                <Column style={ { width: "100%" } }>
                  <input
                    className="form-control"
                    value={ temporarySelectedItem.value || "" }
                    pattern={ props.pattern }
                    onChange={ (event) => this.onInputItemChange(event) }
                  />
                </Column>
              )}
              <Row
                className="actions"
                flexGrow={ 1 }
                horizontal="start"
                wrap={ true }
                vertical="start"
              >
                {selectedItems.length > 0 && (
                  <button
                    className="delete"
                    onClick={ () => this.onDeleteItemChange("") }
                  >
                    X
                  </button>
                )}
                <button className="add" onClick={ () => this.onAddItemChange() }>
                  <span className="plus"> + </span>
                </button>
              </Row>
            </Row>
          )}
          <Column style={ { width: "100%" } }>
            {showError && (
              <span className="error-message"> {errorMessage} </span>
            )}
          </Column>
        </Column>
      </Column>
    );
  }
}

export default Multiselect;
