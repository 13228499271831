import React, { Component } from 'react';
import { Panel } from "react-bootstrap";
import Datetime from 'react-datetime';
import DatePicker from 'react-datepicker';
import { Column, Row } from 'simple-flexbox';
import { connect } from 'react-redux';

import CustomButton from "@sm/components/custom/Button";
import Dropdowns from '@modules/apiData/dropdowns';
import DashBoardRevenueComponent from "@modules/apiData/dashBoardPanelComponents/DashBoardRevenueComponent";
import DashBoardPurchasesComponent from "@modules/apiData/dashBoardPanelComponents/DashBoardPurchasesComponent";
import DashBoardSettlementsComponent from "@modules/apiData/dashBoardPanelComponents/DashBoardSettlementsComponent";
import DashBoardPayoutComponent from "@modules/apiData/dashBoardPanelComponents/DashBoardPayoutComponent";
import DashBoardPurchaseCountriesComponent from "@modules/apiData/dashBoardPanelComponents/DashBoardPurchaseCountriesComponent";
import DashBoardCustomerBalancesComponent from "@modules/apiData/dashBoardPanelComponents/DashBoardCustomerBalancesComponent";
import LastUpdateComponent from "@modules/apiData/dashBoardPanelComponents/LastUpdateComponent";
import DashBoardPayoutCountriesComponent from "@modules/apiData/dashBoardPanelComponents/DashBoardPayoutCountriesComponent";
import DashBoardDepositsPerMidComponent from "@modules/apiData/dashBoardPanelComponents/DashBoardDepositsPerMidComponent";
import DashBoardPayoutsPerMidComponent from "@modules/apiData/dashBoardPanelComponents/DashBoardPayoutsPerMidComponent";
import DashBoardDepositVolumePerMidComponent from '@modules/apiData/dashBoardPanelComponents/DashBoardDepositVolumePerMidComponent';
import DashBoardTransactionsComponent from "@modules/apiData/dashBoardChartsComponents/DashBoardTransactionsComponent";
import DashBoardMerchantWithoutActivityComponent from "@modules/apiData/dashBoardPanelComponents/DashBoardMerchantWithoutActivityComponent";
import Form from "@sm/core/Form";
import Multiselect from "@sm/components/custom/customMultiselect";
import Select from "@sm/components/custom/Select";

import { DASHBOARD_PURCHASES_EVENT, DASHBOARD_FTD_EVENT, DASHBOARD_REVENUE_EVENT, 
  DASHBOARD_PURCHASE_COUNTRIES_EVENT, DASHBOARD_CUSTOMER_BALANCES_EVENT, DASHBOARD_HISTOGRAM_EVENT, 
  DASHBOARD_PAYOUT_COUNTRIES_EVENT, DASHBOARD_PAYOUT_EVENT, DASHBOARD_DEPOSITS_PER_MIDS,
  DASHBOARD_PAYOUTS_PER_MIDS, DASHBOARD_AMOUNTS_PER_MID, DASHBOARD_SETTLEMENTS, DASHBOARD_MERCHANT_WITHOUT_ACTIVITY } from '@sm/actions/types';

import "react-datepicker/dist/react-datepicker.css";
import '@assets/css/apiData.css';
import Calendar from '@assets/images/calendar.png';

import * as actions from '@sm/actions';
import { crudActions } from "@sm/services/crudActions";
import { getFormattedDate, convertUTCToLocalDate, convertLocalToUTCDate } from "@sm/utils/utils";
import { FETCH_PERMISSIONS } from "@sm/actions/types";
const combineReducers = require('@sm/reducers/index');

class APIData extends Component {
  state = {
    controls: {
		  timePeriod: {
        value: 'today'
      },
      from: this.getPeriod(1).from,
      to: this.getPeriod(1).to
    },
    periods: [],
    periodsForMerchantWithoutActivity: ['lastHour', 'today', 'thisMonth', 'thisYear'],
    timeStamp: {
      fromDateTimeStamp: this.getPeriod(0).from,
      toDateTimeStamp: this.getPeriod(0).to
    },
    controlsForm: new Form({
      whiteLabelId: "",
      clients: [],
      providers: [],
      timeZone: "",
      platform: "all",
      sortBy: "merchantName"
    }, [{
      name: "whiteLabelId",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "clients",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "providers",
      type: "isArray",
      rules: {
        min: 1,
        required: true
      }
    }, {
      name: "timeZone",
      type: "isString",
      rules: {
        required: true
      }
    }, {
      name: "sortBy",
      type: "isString",
      rules: {
        required: false
      }
    }]),
    sortByOptions: [{
      label: "Merchants",
      value: "merchantName"
    }, {
      label: "Providers",
      value: "channel"
    }, {
      label: "Currency",
      value: "currency"
    }],

    dateFieldDisabled: false,
    companies: [],
    companiesClients: {},
    companiesProviders: {},
    companiesTimezones: {},
    companiesDefaultTimezones: {},
    companiesTimezoneTypes: {},
    lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY'),

    isSubmitting: true,
    roleId: "",
    clientChosen: false,
    sortBy: "merchantName",
    periodId: 0  //today
  };

  subscribeFunction = null;
  thisMonth = false;

  componentDidMount() {
    const storeState = combineReducers.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        roleId: storeState.roleId
      });
    }

    this.subscribeFunction = combineReducers.default.subscribe(() => {
      const state = combineReducers.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          roleId: state.roleId
        });
      }
    });
    // manually adjust periods
    const updatedPeriods = [...Dropdowns.periods].filter(p => p.id !== 7);
    updatedPeriods.splice(7, 0, { id: 7, value: 'last30Mins', label:'Last 30 Minutes'}, { id: 9, value: 'last15Mins', label:'Last 15 Minutes'});

    this.setState({
      periods: updatedPeriods
    });

    this.loadRestData();
  };

  loadRestData = () => {
    return crudActions.get("v1/companies/clients").then(companiesData => {
      if (companiesData) {
        const companies = companiesData.map(elem => elem.company);
        companies.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
        const companiesClients = companiesData.reduce((accumulator, currentElem) => {
          accumulator[currentElem.company.value] = currentElem.clients.map(elem => elem.client);
          return accumulator;
        }, {});

        const companiesProviders = companiesData.reduce((accumulator, currentElem) => {
          accumulator[currentElem.company.value] = currentElem.psps.map(elem => ({
            label: elem.label,
            value: elem.shortName
          }));
          return accumulator;
        }, {});

        const companiesTimezones = companiesData.reduce((accumulator, currentElem) => {
          accumulator[currentElem.company.value] = currentElem.timeZones;
          return accumulator;
        }, {});

        const companiesDefaultTimezones = companiesData.reduce((accumulator, currentElem) => {
          accumulator[currentElem.company.value] = currentElem.defaultTimezone;
          return accumulator;
        }, {});

        const companiesTimezoneTypes = companiesData.reduce((accumulator, currentElem) => {
          accumulator[currentElem.company.value] = currentElem.timeZoneType;
          return accumulator;
        }, {});

        const updateObject = {
          companies,
          companiesClients,
          companiesProviders,
          companiesTimezones,
          companiesDefaultTimezones,
          companiesTimezoneTypes,
          isSubmitting: false
        };

        if (companies.length) {
          const firstCompany = companies[0].value;
          updateObject.controlsForm = Object.assign(this.state.controlsForm, {
            whiteLabelId: firstCompany,
            clients: companiesClients[firstCompany].map(elem => elem.value),
            providers: companiesProviders[firstCompany].map(elem => elem.value),
            timeZone: companiesTimezones[firstCompany].length ? companiesTimezones[firstCompany].find(tz => tz.value === companiesDefaultTimezones[firstCompany]).value : ""
          });
          
        }

        this.setState(updateObject, () => {
          this.onSelectPeriodChange(this.state.periods[0], 'timePeriod');
        });
      }
    });
  };

  getPeriod(periodType) {
    let timeZoneOffset = "00:00";
    if (this.state) {
      const { companiesTimezoneTypes, controlsForm, companiesTimezones } = this.state;
      const { timeZone, whiteLabelId } = controlsForm;
      const selectedCompanyTimezoneType = companiesTimezoneTypes[controlsForm.whiteLabelId];
      if (whiteLabelId && timeZone && companiesTimezones[whiteLabelId] && selectedCompanyTimezoneType !== "TZ_V2") {
        timeZoneOffset = companiesTimezones[whiteLabelId].find(zone => zone.value === timeZone).offset || "00:00";
      }
    }

    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'day').hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'day').hour(23).minute(59).second(59),
        id: periodType
      };
    case 2: // this month
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month()).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).hour(23).minute(59).second(59),
        id: periodType
      };
    case 3: // last month
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month() - 1).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(moment().utc().utcOffset(timeZoneOffset).month() - 1)
          .date(moment().utc().utcOffset(timeZoneOffset).month(moment().utc().utcOffset(timeZoneOffset).month() - 1).daysInMonth()).hour(23).minute(59).second(59),
        id: periodType
      };
    case 4: // this year
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year()).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).hour(23).minute(59).second(59),
        id: periodType
      };
    case 5: // last year
      return {
        from: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year() - 1).month(0).date(1).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset).year(moment().utc().utcOffset(timeZoneOffset).year() - 1).month(11).date(31).hour(23).minute(59).second(59),
        id: periodType
      };
    case 6: // last hour
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(1, 'hour'),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    case 7: // last 30 minutes
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(30, 'minutes'),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    case 9: // last 15 minutes
      return {
        from: moment().utc().utcOffset(timeZoneOffset).subtract(15, 'minutes'),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc().utcOffset(timeZoneOffset).hour(0).minute(0).second(0),
        to: moment().utc().utcOffset(timeZoneOffset),
        id: periodType
      };
    }
  };

  onSelectPeriodChange = (value, id) => {
    const timeStamp = { ...this.state.timeStamp };
    const controls = { ...this.state.controls };
    value = value === null ? {
      id: 0,
      value: 'today',
      label: 'Today'
    } : value;
    const dateFieldDisabled = value.id > 0 && value.id !== 8; // depends on any predefined period (today, yesterday etc.)
    const period = this.getPeriod(value.id);

    controls[id] = value;
    timeStamp.fromDateTimeStamp = period.from;
    timeStamp.toDateTimeStamp = period.to;
    controls.from = getFormattedDate(timeStamp.fromDateTimeStamp._d);
    controls.to = getFormattedDate(timeStamp.toDateTimeStamp._d);

    //if this month
    if (value.id === 2) {
      this.thisMonth = true;
    } else {
      this.thisMonth = false;
    }

    this.setState({
      controls: controls,
      dateFieldDisabled: dateFieldDisabled,
      timeStamp: timeStamp,
      periodId: value.id
    });
  };

  mapSelectedItems = (type, elementsId) => {
    const { controlsForm } = this.state;
    const { whiteLabelId } = controlsForm;

    if (!whiteLabelId) return [];

    const selected = controlsForm[type];
    const companiesElements = this.state[elementsId];

    const clients = companiesElements[whiteLabelId];
    return selected.map(elem => {
      return clients.find(client => client.value === elem);
    });
  };

  onValueChange = (event, field) => {
    const { controlsForm, companiesClients, companiesProviders, companiesDefaultTimezones, companiesTimezoneTypes, companiesTimezones } = this.state;
    if (!event) {
      controlsForm[field] = event;
    } else if (event.target) {
      controlsForm[field] = event.target.value;
    } else if (event.value) {
      controlsForm[field] = event.value;
    } else {
      controlsForm[field] = event.map(elem => elem.value);
    }

    if (field === "whiteLabelId") {
      controlsForm.clients = companiesClients[event.value].map(elem => elem.value);
      controlsForm.providers = companiesProviders[event.value].map(elem => elem.value);
      const tzType = companiesTimezoneTypes[event.value];
      controlsForm.timeZone = tzType === "TZ_V1" ? (companiesDefaultTimezones[event.value] || "") : companiesTimezones[event.value][0].value;
      this.onSelectPeriodChange(this.state.periods.find(p => p.id === this.state.periodId), this.state.periodId);
    } else if (field === "timeZone") {
      const currentPeriod = this.state.periods.find(elem => elem.value === this.state.controls.timePeriod.value);
      this.onSelectPeriodChange(currentPeriod, "timePeriod");
    }

    this.setState({
      controlsForm
    });
  };

  handleDateChange = (event, state, fieldState) => {
    const controls = { ...this.state.controls };
    const timeStamp = { ...this.state.timeStamp };

    timeStamp[state] = event;
    controls[fieldState] = event;

    this.setState({ controls: controls, timeStamp: timeStamp });
  };

  isDateValid = (current, state) => {
    const { controlsForm, companiesTimezones, companiesTimezoneTypes } = this.state;
    const fromDate = this.state.timeStamp.fromDateTimeStamp;
    const toDate = this.state.timeStamp.toDateTimeStamp;

    const timeZones = companiesTimezones[controlsForm.whiteLabelId];
    const selectedCompanyTimezoneType = companiesTimezoneTypes[controlsForm.whiteLabelId];
    let timeZoneOffset = "00:00";
    if (controlsForm.timeZone && timeZones.length && selectedCompanyTimezoneType !== "TZ_V2") {
      timeZoneOffset = timeZones.find(zone => zone.value === controlsForm.timeZone).offset || "00:00";
    }

    const yesterday = Datetime.moment().utc().utcOffset(timeZoneOffset).subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return Datetime.moment(current).isBefore(!fromDate ? yesterday : toDate);
    }

    return Datetime.moment(current).isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  onChangeFilter = (e) => {
    const { controlsForm } = this.state;
    const isFormValid = controlsForm.isFormValid();

    e.preventDefault();

    this.setState({
      controlsForm
    });

    if (isFormValid) {
      this.setState({
        clientChosen: true
      });

      this.loadDashboardData();
    }
  };

  loadDashboardData = () => {
    const { controls, controlsForm, periodId, timeStamp, companiesProviders, companiesTimezones, companiesTimezoneTypes, periodsForMerchantWithoutActivity, periods, roleId } = this.state;
    const { clients, platform, providers, timeZone, whiteLabelId } = controlsForm;
    const period = this.getPeriod(periodId);

    if (periodId !== 8) {
      //we do not override dates for custom date picker
      timeStamp.fromDateTimeStamp = period.from;
      timeStamp.toDateTimeStamp = period.to;
    }

    const timeZones = companiesTimezones[whiteLabelId];
    const selectedCompanyTimezoneType = companiesTimezoneTypes[controlsForm.whiteLabelId];
    let timeZoneOffset = "00:00";
    if (timeZone && timeZones.length && selectedCompanyTimezoneType !== "TZ_V2") {
      timeZoneOffset = timeZones.find(zone => zone.value === timeZone).offset || "00:00";
    }

    const dateFormat = "DD.MM.YYYY-HH:mm:ss";

    let fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);
    let toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).utc().utcOffset(timeZoneOffset).format(dateFormat);
        
    if (periodId === 8) {
      fromDateTimeStamp = Datetime.moment(timeStamp.fromDateTimeStamp).format(dateFormat);
      toDateTimeStamp = Datetime.moment(timeStamp.toDateTimeStamp).format(dateFormat);
    }

    combineReducers.default.dispatch({ type: "LOADING" });
    this.setState({
      lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY'),
      sortBy: controlsForm.sortBy
    });

    let selectedProviders = providers.join(',');
    let allProviders = false;
    if (providers.length === companiesProviders[whiteLabelId].length && roleId !== "PROVIDER_ADMIN") {
      selectedProviders = [];
      allProviders = true;
    }

    const requestParams = `fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${clients.join(',')}&providers=${providers.join(',')}&allProviders=${allProviders}&timeZone=${timeZone}&timeZoneType=${selectedCompanyTimezoneType}&platform=${platform}&timePeriod=${controls.timePeriod.value}`;

    if (roleId !== "PROVIDER_ADMIN") {
      crudActions.get(`v2/master/dashboard/revenue?${requestParams}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_REVENUE_EVENT, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/country/purchases?${requestParams}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_PURCHASE_COUNTRIES_EVENT, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/payout?${requestParams}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_EVENT, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/ftd?${requestParams}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_PURCHASES_EVENT, payload: data.purchases});
          combineReducers.default.dispatch({type: DASHBOARD_FTD_EVENT, payload: data.ftds});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/customer/balances?${requestParams}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_CUSTOMER_BALANCES_EVENT, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/transactions?${requestParams}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_HISTOGRAM_EVENT, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/amounts-per-mid?${requestParams}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_AMOUNTS_PER_MID, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });
      if (periodsForMerchantWithoutActivity.includes(periods[periodId].value)) {
        crudActions.get(`v2/master/dashboard/merchants-without-activity?${ requestParams }`)
          .then(data => {
            combineReducers.default.dispatch({ type: DASHBOARD_MERCHANT_WITHOUT_ACTIVITY, payload: data });
          })
          .catch(e => {
            this.props.showMessage(e.message);
          });
      }

      crudActions.get(`v2/master/dashboard/settlements?${requestParams}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_SETTLEMENTS, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });

      crudActions.get(`v2/master/dashboard/country/payout?${requestParams}`)
        .then(data => {
          combineReducers.default.dispatch({type: DASHBOARD_PAYOUT_COUNTRIES_EVENT, payload: data});
        })
        .catch(e => {
          this.props.showMessage(e.message);
        });
    }
    
    crudActions.get(`v2/master/dashboard/deposits-per-mid?fromDate=${fromDateTimeStamp}&toDate=${toDateTimeStamp}&thisMonth=${this.thisMonth}&clients=${clients.join(',')}&providers=${selectedProviders}&allProviders=${allProviders}&timeZone=${timeZone}&timeZoneType=${selectedCompanyTimezoneType}&platform=${platform}&timePeriod=${controls.timePeriod.value}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_DEPOSITS_PER_MIDS, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });

    crudActions.get(`v2/master/dashboard/payouts-per-mid?${requestParams}`)
      .then(data => {
        combineReducers.default.dispatch({type: DASHBOARD_PAYOUTS_PER_MIDS, payload: data});
      })
      .catch(e => {
        this.props.showMessage(e.message);
      });
  };

  render() {

    const controls = {...this.state.controls};
    const {
      clientChosen,
      companies,
      companiesClients,
      companiesProviders,
      companiesTimezones,
      controlsForm,
      dateFieldDisabled,
      periods,
      isSubmitting,
      lastUpdate,
      roleId,
      sortBy,
      sortByOptions,
      timeStamp,
      periodsForMerchantWithoutActivity
    } = this.state;

    const thisMonth = this.thisMonth;

    const providerAdmin = roleId === "PROVIDER_ADMIN";

    return (
      <Row flexGrow={ 1 } className='module apidata' vertical='start'>
        <Column flexGrow={ 1 }>
          <LastUpdateComponent lastUpdate={ lastUpdate }/>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, paddingTop: 15, width: '100%' } }>
              <Panel>
                <Panel.Heading>
                  <Panel.Title> DASHBOARD SETTINGS </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                  <div className="panel-content" style={ {overflow: 'unset'} }>
                    <form>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> White Label </label>
                          <Select
                            id="whiteLabelId"
                            name="whiteLabelId"
                            value={ controlsForm.whiteLabelId }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onValueChange(value, "whiteLabelId") }
                            options={ companies }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Merchants </label>
                          <Multiselect
                            isError={ controlsForm.errors.has("clients") }
                            disabled={ !controlsForm.whiteLabelId }
                            selectedItems={ this.mapSelectedItems("clients", "companiesClients") }
                            items={ controlsForm.whiteLabelId ? companiesClients[controlsForm.whiteLabelId] : [] }
                            type={ "clients" }
                            onChange={ (value) => this.onValueChange(value, "clients") }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Providers </label>
                          <Multiselect
                            isError={ controlsForm.errors.has("providers") }
                            disabled={ !controlsForm.whiteLabelId }
                            selectedItems={ this.mapSelectedItems("providers", "companiesProviders") }
                            items={ controlsForm.whiteLabelId ? companiesProviders[controlsForm.whiteLabelId] : [] }
                            type={ "providers" }
                            onChange={ (value) => this.onValueChange(value, "providers") }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label>Time Period</label>
                          <Select
                            id="timePeriod"
                            name="timePeriod"
                            value={ controls.timePeriod.value || '' }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onSelectPeriodChange(value, 'timePeriod') }
                            options={ periods }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column first-datepicker" alignSelf='start'>
                          <label>From Date</label>
                          <img src={ Calendar } className="calendar-svg" alt="" />
                          <DatePicker
                            selected={ convertUTCToLocalDate(timeStamp.fromDateTimeStamp) || '' }
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat="dd.MM.yyyy HH:mm"
                            timeFormat="HH:mm"
                            showTimeSelect={ true }
                            timeIntervals={ 15 }
                            filterDate={ (event) => this.isDateValid(event, 'fromDateTimeStamp') }
                            disabled={ dateFieldDisabled || !controlsForm.whiteLabelId }
                            onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'fromDateTimeStamp', 'from') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } className="input-column" alignSelf='start'>
                          <label>To Date</label>
                          <img src={ Calendar } className="calendar-svg" alt="" />
                          <DatePicker
                            selected={ convertUTCToLocalDate(timeStamp.toDateTimeStamp) || '' }
                            className="form-control"
                            timeInputLabel="Time:"
                            dateFormat="dd.MM.yyyy HH:mm"
                            timeFormat="HH:mm"
                            showTimeSelect={ true }
                            timeIntervals={ 15 }
                            filterDate={ (event) => this.isDateValid(event, 'toDateTimeStamp') }
                            disabled={ dateFieldDisabled || !controlsForm.whiteLabelId }
                            onChange={ (date) => this.handleDateChange(convertLocalToUTCDate(date), 'toDateTimeStamp', 'from') }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Time Zone </label>
                          <Select
                            id="timeZone"
                            name="timeZone"
                            value={ controlsForm.timeZone }
                            required={ true }
                            clearable={ false }
                            className={ controlsForm.errors.has("timeZone") ? "error-field" : "" }
                            onChange={ (value) => this.onValueChange(value, "timeZone") }
                            options={ controlsForm.whiteLabelId ? companiesTimezones[controlsForm.whiteLabelId] : [] }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> Sort By </label>
                          <Select
                            id="sortBy"
                            name="sortBy"
                            value={ controlsForm.sortBy }
                            required={ false }
                            clearable={ false }
                            className={ controlsForm.errors.has("sortBy") ? "error-field" : "" }
                            onChange={ (value) => this.onValueChange(value, "sortBy") }
                            options={ sortByOptions }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } className="input-column fullwidth-button">
                          <CustomButton
                            title="Filter"
                            type="button"
                            disabled={ isSubmitting }
                            onClick={ (e) => this.onChangeFilter(e) }
                          />
                        </Column>
                      </Row>
                    </form>
                  </div>
                </Panel.Body>
              </Panel>
            </Column>
            { clientChosen && !providerAdmin && (
              <Column flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%' } }>
                <DashBoardRevenueComponent thisMonth={ thisMonth }/>
              </Column>
            )}
           
            { clientChosen && (
              <Column flexGrow={ 1 } className="table-column" vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%' } }>
                <DashBoardDepositsPerMidComponent thisMonth={ thisMonth } sortBy={ sortBy } />
              </Column>
            )}

            { clientChosen && !providerAdmin && periodsForMerchantWithoutActivity.includes(controls.timePeriod.value) && (
              <Column flexGrow={ 1 } className="table-column" vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%' } }>
                <DashBoardMerchantWithoutActivityComponent thisMonth={ thisMonth } sortBy={ sortBy } />
              </Column>
            )}

            { clientChosen && (
              <Column flexGrow={ 1 } className="table-column" vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%' } }>
                <DashBoardPayoutsPerMidComponent thisMonth={ thisMonth } sortBy={ sortBy } />
              </Column>
            )}
            
            { clientChosen && !providerAdmin && (
              <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ { width: '100%' } }>
                <Column flexGrow={ 1 } className="table-column" vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%' } }>
                  <DashBoardDepositVolumePerMidComponent thisMonth={ thisMonth }/>
                </Column>
                <Column className="table-column" flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                  <DashBoardSettlementsComponent thisMonth={ thisMonth }/>
                </Column>
              </Row>
            )}
            
            { clientChosen && !providerAdmin && (
              <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ { width: '100%' } }>
                <Column className="table-column" flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                  <DashBoardPurchaseCountriesComponent thisMonth={ thisMonth }/>
                </Column>
                <Column className="table-column" flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                  <DashBoardPayoutCountriesComponent thisMonth={ thisMonth }/>
                </Column>
              </Row>
            )}

            { clientChosen && !providerAdmin && (
              <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ { width: '100%' } }>
                <Column className="table-column" flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                  <DashBoardPurchasesComponent thisMonth={ thisMonth }/>
                </Column>
                <Column className="table-column" flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                  <DashBoardPayoutComponent thisMonth={ thisMonth }/>
                </Column>
              </Row>
            )}
            { clientChosen && !providerAdmin && (
              <Row className="dashboard-row" flexGrow={ 1 } horizontal='start' alignSelf="start" style={ { width: '100%' } }>
                <Column className="table-column" flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                  <DashBoardTransactionsComponent thisMonth={ thisMonth }/>
                </Column>
                <Column className="table-column" flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, width: '100%'} }>
                  <DashBoardCustomerBalancesComponent thisMonth={ thisMonth }/>
                </Column>
              </Row>
            )}
          </Row>
        </Column>
      </Row>
    );
  }
}

export default connect(null, actions)(APIData);
