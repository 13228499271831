import React, { Component } from 'react';
import Datetime from 'react-datetime';
import { Column, Row } from 'simple-flexbox';
import { Panel } from "react-bootstrap";

import Alert from '@sm/components/custom/Alert';
import CustomButton from "@sm/components/custom/Button";
import PageHeader from "@sm/components/PageHeader";
import Multiselect from "@modules/adminPanel/Multiselect";
import Select from "@sm/components/custom/Select";
import Spinner from '@sm/components/Spinner';

import '@sm/assets/css/adminPanel.css';
import AdminPanelIcon from '@sm/assets/images/adminpanel.png';

import { crudActions } from "@sm/services/crudActions";
import { twoFAOptions } from "@sm/utils/twoFAOptions";
import { FETCH_PERMISSIONS } from '@sm/actions/types';
const store =  require('@sm/reducers/index');

class AdminPanel extends Component {
  state = {
    dropdowns: [
      {
        id: "blockedCountries",
        selectedId: "blockedCountries",
        header: "BLOCKED COUNTRIES",
        data: [],
        type: "select",
        deletable: true
      }, {
        id: "currency",
        selectedId: "currency",
        header: "CURRENCIES",
        data: [],
        type: "select",
        deletable: true
      }, {
        id: "otcCurrencies",
        selectedId: "otcCurrencies",
        header: "OTC Currencies",
        data: [],
        type: "select",
        deletable: true,
        disabled: true
      }, {
        id: "fxPairs",
        selectedId: "fxPairs",
        header: "FX Pairs",
        data: [],
        type: "select",
        deletable: true
      }, {
        id: "gateways",
        selectedId: "gateways",
        header: "GATEWAYS",
        data: [],
        type: "input",
        deletable: true
      }, {
        id: "midCategories",
        selectedId: "midCategories",
        header: "MID CATEGORIES",
        data: [],
        type: "input",
        deletable: true
      }, {
        id: "buyMethods",
        selectedId: "paymentMethods",
        header: "PAYMENT METHODS",
        data: [],
        type: "select",
        deletable: true
      }, {
        id: "payoutCountries",
        selectedId: "payoutCountries",
        header: "PAYOUT COUNTRIES",
        data: [],
        type: "select",
        deletable: true
      }, {
        id: "payoutMethods",
        selectedId: "payoutMethods",
        header: "PAYOUT METHODS",
        data: [],
        type: "select",
        deletable: true
      }, {
        id: "timeZones",
        selectedId: "timeZones",
        header: "TIME ZONES",
        data: [],
        type: "select",
        deletable: true
      }, {
        id: "enableTwoFactorAuth",
        selectedId: "enableTwoFactorAuth",
        header: "2FA",
        data: twoFAOptions,
        type: "dropdown"
      }
    ],

    selectedDropdowns: {
      blockedCountries: [],
      currency: [],
      otcCurrencies: [],
      fxPairs: [],
      payoutCountries: [],
      gateways: [],
      midCategories: [],
      payoutMethods: [],
      paymentMethods: [],
      timeZones: [],
      enableTwoFactorAuth: ""
    },

    companies: [],
    selectedCompany: "",
    baseFX: [],

    temporaryItems: {},

    lastUpdate: Datetime.moment().format('HH:mm - DD. MMM YYYY'),
    showAlert: false,
    alertMessage: "",
    alertType: "success",
    updateDataOnConfirm: false,

    removeEmptyField: false,
    showPanels: false,

    loadingSettings: true,
    loadingLookups: true,

    access: []
  };

  pairsRef = React.createRef();
  subscribeFunction = null;

  componentDidMount() {
    const storeState = store.default.getState().authReducer;
    if (storeState.access) {
      this.setState({
        access: storeState.access
      });
    }

    this.subscribeFunction = store.default.subscribe(() => {
      const state = store.default.getState().authReducer;

      if (state.userUpdate === FETCH_PERMISSIONS) {
        this.setState({
          access: state.access
        });
      }
    });

    Promise.all([
      crudActions.get('v1/adminpanel/lookups'),
      crudActions.get('v1/companies/labels')
    ]).then(
      (data) => {
        const dropdowns = data[0];
        const companies = data[1];
        if (dropdowns) {
          const stateDropdowns = this.state.dropdowns;
          stateDropdowns[0].data = dropdowns.country;
          stateDropdowns[1].data = dropdowns.currency;
          stateDropdowns[2].data = dropdowns.baseFX;
          
          stateDropdowns[6].data = dropdowns.buyMethods;
          stateDropdowns[7].data = dropdowns.country;
          stateDropdowns[8].data = dropdowns.payoutMethods;
          stateDropdowns[9].data = dropdowns.timeZones || [];
          // stateDropdowns[10].data = dropdowns.enableTwoFactorAuth;

          this.setState({
            dropdowns: stateDropdowns,
            companies: companies ? companies : [],
            selectedCompany: companies ? companies[0].value : "",
            loadingLookups: false,
            baseFX: dropdowns.baseFX
          });
        }
      }
    );
  };

  componentWillUnmount() {
    if (this.subscribeFunction) {
      this.subscribeFunction();
    }
  };

  checkPageAccess = (permissionName) => {
    const { access } = this.state;
    const foundPermission = access.find(elem => elem.permission === permissionName);
    if (!foundPermission) {
      return false;
    }

    return foundPermission.state;
  };

  loadActualSettings = () => {
    const { selectedCompany } = this.state;
    if (selectedCompany) {
      crudActions.get(`v1/adminpanel/company/${selectedCompany}`).then(
        (dropdowns) => {
          if (dropdowns) {
            const selectedDropdowns = this.state.selectedDropdowns;
            if (!dropdowns || !dropdowns.storedLookups) {
              return;
            }

            const storedLookups = dropdowns.storedLookups;
            const updated = Datetime.moment(storedLookups.updated).format('HH:mm - DD. MMM YYYY');
            delete storedLookups.updated;
            storedLookups.language = [];
            storedLookups.withdrawalMethods = [];

            this.setState({
              selectedDropdowns: Object.assign(selectedDropdowns, storedLookups, {
                otcCurrencies: this.state.baseFX.map(item => { return item.value; })
              }),
              lastUpdate: updated,
              loadingSettings: false
            });
          }
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
            });
          }
        }
      );
    }
  };

  //Map functions
  mapSelectedItems = (fieldId, selectedFieldId, areSelected) => {
    const { baseFX, temporaryItems } = this.state;

    const sortStrings = (array) => {
      let sortProperty = "label";
      if (fieldId === "timeZones") {
        sortProperty = "offset";
      }
      array.sort((elemA, elemB) => {
        if (elemA[sortProperty] < elemB[sortProperty])
          return -1;
        if (elemA[sortProperty] > elemB[sortProperty])
          return 1;
        return 0;
      });
    };

    const selectedDropdown = this.state.selectedDropdowns[selectedFieldId];
    const dropdown = this.state.dropdowns.find(dropdwn => dropdwn.id === fieldId);
    if (dropdown.type === "select") {
      let data = dropdown.data;
      if (fieldId === "fxPairs") {
        data = this.getFXPairsOptions();
      }
      if (fieldId === "otcCurrencies") {
        data = baseFX;
      }
      const dropdowns = (selectedDropdown || []).map(selectedItem => data.find(elem => elem.value === selectedItem));
      if (!areSelected && temporaryItems[fieldId]) {
        dropdowns.push(data.find(elem => elem.value === temporaryItems[fieldId]));
      }

      sortStrings(dropdowns);
      return dropdowns;
    }

    const dropdowns = selectedDropdown.map(selectedItem => {
      return {
        value: selectedItem,
        label: selectedItem
      };
    });

    if (!areSelected && temporaryItems[fieldId]) {
      dropdowns.push({
        value: temporaryItems[fieldId],
        label: temporaryItems[fieldId]
      });
    }

    sortStrings(dropdowns);

    return dropdowns;
  };

  mapBuysSells = (fieldValue, type) => {
    const dropdownData = this.state.dropdowns.find(dropdown => dropdown.id === type).data;
    return dropdownData.find(elem => fieldValue === elem.value);
  };

  onMultiselectChange = (id, value) => {
    const { selectedDropdowns, temporaryItems } = this.state;
    selectedDropdowns[id] = value;
    this.setState({
      selectedDropdowns: selectedDropdowns,
      temporaryItems: Object.assign(temporaryItems, { [id]: null })
    });
  };

  getFXPairsOptions = () => {
    const { baseFX, selectedDropdowns } = this.state;
    const { currency } = selectedDropdowns;
    const options = [];

    baseFX.forEach(baseItem => {
      currency.forEach(item => {
        options.push({
          value: `${baseItem.value}/${item}`,
          label: `${baseItem.value}/${item}`
        });
      });
    });
    return options;
  };

  onSubmitAdminPanel = () => {
    const { selectedDropdowns, selectedCompany, temporaryItems } = this.state;

    const updateObject = {
      showAlert: true,
      alertType: "success",
      alertMessage: "",
      updateDataOnConfirm: true,
      removeEmptyField: true
    };

    const temporaryKeys = Object.keys(temporaryItems);
    for (let i = 0; i < temporaryKeys.length; i++) {
      const key = temporaryKeys[i];
      if (temporaryItems[key] && !selectedDropdowns[key].find(elem => elem === temporaryItems[key])) {
        selectedDropdowns[key].push(temporaryItems[key]);
      }
    }

    this.setState({
      loadingSettings: true
    });

    crudActions.post(`v1/adminpanel/company/${selectedCompany}`, {
      storedLookups: selectedDropdowns,
      buys: [],
      sells: []
    }).then(
      (result) => {
        this.setState({
          updateObject,
          showAlert: true,
          alertType: "success",
          alertMessage: "",
          loadingSettings: false
        });
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
            loadingSettings: false
          });
        }
      }
    );
  };

  onConfirm = () => {
    const updateDataOnConfirm = this.state.updateDataOnConfirm ? this.loadActualSettings : null;

    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: "",
      updateDataOnConfirm: false,
      removeEmptyField: false
    }, updateDataOnConfirm);
  };

  onSelectItemChange = (event, field) => {
    const { selectedCompany, selectedDropdowns } = this.state;

    crudActions.post(`v1/adminpanel/company/${selectedCompany}/two-factor`, {
      enableTwoFactorAuth: event.value
    }).then(response => {
      this.setState({
        selectedDropdowns: Object.assign(selectedDropdowns, {
          [field]: event.value
        }),
        showAlert: true,
        alertType: "success",
        alertMessage: "2FA settings successfully updated."
      });
    }).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message
          });
        }
      });
  };

  onTemporaryChange = (id, value) => {
    this.setState({
      temporaryItems: Object.assign(this.state.temporaryItems, { [id]: value })
    });
  };

  onCompanyChange = (event) => {
    this.setState({
      selectedCompany: event.value,
      showPanels: false
    });
  };

  onUpdateClick = () => {
    this.setState({
      showPanels: true,
      loadingSettings: true
    }, this.loadActualSettings);
  };

  render() {
    const { loadingLookups, showPanels, dropdowns, showAlert, alertType, alertMessage,
      removeEmptyField, loadingSettings, companies, selectedCompany, selectedDropdowns } = this.state;

    return (
      <Row flexGrow={ 1 } className="module apidata adminPanel" vertical='start'>
        <Column flexGrow={ 1 }>
          <PageHeader
            title={ 'Admin Panel' }
            lastUpdate={ this.state.lastUpdate }
            img={ AdminPanelIcon }
          />
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' style={ { paddingLeft: 15, paddingRight: 15, paddingTop: 15, width: '100%' } }>
              { loadingLookups ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> SELECT WHITE LABEL </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      SELECT WHITE LABEL
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ {overflow: 'unset'} }>
                      <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='end'>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <label> White Label </label>
                          <Select
                            name="company"
                            value={ selectedCompany }
                            required={ true }
                            clearable={ false }
                            onChange={ (value) => this.onCompanyChange(value) }
                            options={ companies }
                          />
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' alignSelf='start' className="input-column">
                          <span/>
                        </Column>
                        <Column flexGrow={ 1 } vertical='start' className="input-column">
                          <CustomButton
                            title="Update"
                            type="submit"
                            disabled={ !selectedCompany || showPanels }
                            onClick={ () => this.onUpdateClick() }
                          />
                        </Column>
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              )}

              { showPanels && selectedCompany && (
                <div>
                  { loadingSettings ? (
                    <Panel>
                      <Panel.Heading>
                        <Panel.Title> PROPERTY SETTINGS </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body>
                        <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                          <Spinner smallContainer={ true } />
                        </div>
                      </Panel.Body>
                    </Panel>
                  ) : (
                    <Panel>
                      <Panel.Heading>
                        <Panel.Title>
                          PROPERTY SETTINGS
                        </Panel.Title>
                      </Panel.Heading>
                      <Panel.Body>
                        <div className="panel-content" style={ {overflow: 'unset'} }>
                          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
                            {dropdowns.slice(0, 4).map(field => {
                              return (
                                <Column key={ field.id } flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                                  <Multiselect
                                    id={ field.id }
                                    selectedId={ field.selectedId }
                                    header={ field.header }
                                    items={ field.id === "fxPairs" ? this.getFXPairsOptions() : field.data }
                                    type={ field.type }
                                    update={ removeEmptyField }
                                    selectedItems={ this.mapSelectedItems(field.id, field.selectedId, true) }
                                    onChange={ this.onMultiselectChange }
                                    deletable={ field.deletable && this.checkPageAccess("ADMIN_PANEL_EDIT") }
                                    disabled={ field.disabled }
                                    onTemporaryChange={ this.onTemporaryChange }
                                  />
                                </Column>
                              );
                            })}
                          </Row>
                          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
                            {dropdowns.slice(4, 8).map(field => {
                              return (
                                <Column key={ field.id } flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                                  <Multiselect
                                    id={ field.id }
                                    selectedId={ field.selectedId }
                                    header={ field.header }
                                    items={ field.data }
                                    type={ field.type }
                                    update={ removeEmptyField }
                                    selectedItems={ this.mapSelectedItems(field.id, field.selectedId, true) }
                                    onChange={ this.onMultiselectChange }
                                    deletable={ field.deletable && this.checkPageAccess("ADMIN_PANEL_EDIT") }
                                    disabled={ field.disabled }
                                    onTemporaryChange={ this.onTemporaryChange }/>
                                </Column>
                              );
                            })}
                          </Row>
                          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
                            {dropdowns.slice(8).map(field => {
                              return (
                                <Column key={ field.id } flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                                  {field.type !== "dropdown" ? (
                                    <Multiselect
                                      id={ field.id }
                                      selectedId={ field.selectedId }
                                      header={ field.header }
                                      items={ field.data }
                                      type={ field.type }
                                      update={ removeEmptyField }
                                      selectedItems={ this.mapSelectedItems(field.id, field.selectedId, true) }
                                      onChange={ this.onMultiselectChange }
                                      deletable={ field.deletable && this.checkPageAccess("ADMIN_PANEL_EDIT") }
                                      disabled={ field.disabled }
                                      onTemporaryChange={ this.onTemporaryChange }
                                    />
                                  ) : (
                                    <Column className="multiselect" flexGrow={ 1 } vertical="start" alignSelf="start" >
                                      <Row className="multiselect-header">{field.header}</Row>
                                      <Select
                                        className="multiselect-select single-select"
                                        value={ selectedDropdowns[field.id] || "" }
                                        clearable={ false }
                                        onChange={ (event) => this.onSelectItemChange(event, field.id) }
                                        options={ field.data }
                                      />
                                    </Column>
                                  )}
                                </Column>
                              );
                            })}
                            <Column flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                              <span/>
                            </Column>
                          </Row>
                        </div>
                        <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='start'>
                          {[0, 1, 2].map(elem => (
                            <Column key={ elem } flexGrow={ 1 } vertical='start' style={ { margin: '10px' } } className="input-column">
                              <span/>
                            </Column>
                          ))}
                          <Column flexGrow={ 1 } vertical='end' className="input-column button-block">
                            { !loadingSettings && this.checkPageAccess("ADMIN_PANEL_EDIT") && (
                              <CustomButton
                                title="Save"
                                type="submit"
                                onClick={ () => this.onSubmitAdminPanel() }
                              />
                            )}
                          </Column>
                        </Row>
                      </Panel.Body>
                    </Panel>
                  )}
                </div>
              )}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertType === "success" && !alertMessage ? "Settings successfully saved." : alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

      </Row>
    );
  }
};

export default AdminPanel;